@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Livvic:wght@400;500;600;700&display=swap");
@media (min-width: 1400px) {
  .container {
    max-width: 1140px !important;
  }
}

.row > * {
    position: relative;
  }
  
  h1, .h1 {
    font-size: 42px !important;
  }
  
  h2, .h2 {
    font-size: 36px !important;
  }
  
  h3, .h3 {
    font-size: 30px !important;
  }
  
  h4, .h4 {
    font-size: 24px !important;
  }
  
  h5, .h5 {
    font-size: 20px !important;
  }
  
  h6, .h6 {
    font-size: 16px !important;
  }
  
  .display-1 {
    font-size: 80px !important;
  }
  
  .display-2 {
    font-size: 72px !important;
  }
  
  .display-3 {
    font-size: 64px !important;
  }
  
  .display-4 {
    font-size: 56px !important;
  }
  
  .bg-primary {
    background-color: #2f55d4 !important;
  }
  
  .bg-soft-primary {
    background-color: rgba(47, 85, 212, 0.1) !important;
    border: 1px solid rgba(47, 85, 212, 0.1) !important;
    color: #2f55d4 !important;
  }
  
  .text-primary {
    color: #2f55d4 !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-primary:hover, a.text-primary:focus {
    color: #284bc1 !important;
  }
  
  .bg-secondary {
    background-color: #5a6d90 !important;
  }
  
  .bg-soft-secondary {
    background-color: rgba(90, 109, 144, 0.1) !important;
    border: 1px solid rgba(90, 109, 144, 0.1) !important;
    color: #5a6d90 !important;
  }
  
  .text-secondary {
    color: #5a6d90 !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-secondary:hover, a.text-secondary:focus {
    color: #506180 !important;
  }
  
  .bg-success {
    background-color: #2eca8b !important;
  }
  
  .bg-soft-success {
    background-color: rgba(46, 202, 139, 0.1) !important;
    border: 1px solid rgba(46, 202, 139, 0.1) !important;
    color: #2eca8b !important;
  }
  
  .text-success {
    color: #2eca8b !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-success:hover, a.text-success:focus {
    color: #29b57d !important;
  }
  
  .bg-warning {
    background-color: #f17425 !important;
  }
  
  .bg-soft-warning {
    background-color: rgba(241, 116, 37, 0.1) !important;
    border: 1px solid rgba(241, 116, 37, 0.1) !important;
    color: #f17425 !important;
  }
  
  .text-warning {
    color: #f17425 !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-warning:hover, a.text-warning:focus {
    color: #ed650f !important;
  }
  
  .bg-info {
    background-color: #17a2b8 !important;
  }
  
  .bg-soft-info {
    background-color: rgba(23, 162, 184, 0.1) !important;
    border: 1px solid rgba(23, 162, 184, 0.1) !important;
    color: #17a2b8 !important;
  }
  
  .text-info {
    color: #17a2b8 !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-info:hover, a.text-info:focus {
    color: #148ea1 !important;
  }
  
  .bg-danger {
    background-color: #e43f52 !important;
  }
  
  .bg-soft-danger {
    background-color: rgba(228, 63, 82, 0.1) !important;
    border: 1px solid rgba(228, 63, 82, 0.1) !important;
    color: #e43f52 !important;
  }
  
  .text-danger {
    color: #e43f52 !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-danger:hover, a.text-danger:focus {
    color: #e1293e !important;
  }
  
  .bg-dark {
    background-color: #3c4858 !important;
  }
  
  .bg-soft-dark {
    background-color: rgba(60, 72, 88, 0.1) !important;
    border: 1px solid rgba(60, 72, 88, 0.1) !important;
    color: #3c4858 !important;
  }
  
  .text-dark {
    color: #3c4858 !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-dark:hover, a.text-dark:focus {
    color: #323c49 !important;
  }
  
  .bg-muted {
    background-color: #8492a6 !important;
  }
  
  .bg-soft-muted {
    background-color: rgba(132, 146, 166, 0.1) !important;
    border: 1px solid rgba(132, 146, 166, 0.1) !important;
    color: #8492a6 !important;
  }
  
  .text-muted {
    color: #8492a6 !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-muted:hover, a.text-muted:focus {
    color: #75859b !important;
  }
  
  .bg-light {
    background-color: #f8f9fc !important;
  }
  
  .bg-soft-light {
    background-color: rgba(248, 249, 252, 0.1) !important;
    border: 1px solid rgba(248, 249, 252, 0.1) !important;
    color: #f8f9fc !important;
  }
  
  .text-light {
    color: #f8f9fc !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-light:hover, a.text-light:focus {
    color: #e6eaf4 !important;
  }
  
  .bg-blue {
    background-color: #2f55d4 !important;
  }
  
  .bg-soft-blue {
    background-color: rgba(47, 85, 212, 0.1) !important;
    border: 1px solid rgba(47, 85, 212, 0.1) !important;
    color: #2f55d4 !important;
  }
  
  .text-blue {
    color: #2f55d4 !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-blue:hover, a.text-blue:focus {
    color: #284bc1 !important;
  }
  
  .bg-footer {
    background-color: #202942 !important;
  }
  
  .bg-soft-footer {
    background-color: rgba(32, 41, 66, 0.1) !important;
    border: 1px solid rgba(32, 41, 66, 0.1) !important;
    color: #202942 !important;
  }
  
  .text-footer {
    color: #202942 !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-footer:hover, a.text-footer:focus {
    color: #181e30 !important;
  }
  
 
  .bg-black {
    background: #161c2d !important;
  }
  
  video:focus {
    outline: none;
  }
  
  .list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 5px;
  }
  
  .rounded {
    border-radius: 6px !important;
  }
  
  .rounded-top {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
  
  .rounded-left {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }
  
  .rounded-bottom {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
  
  .rounded-right {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
  
  .rounded-md {
    border-radius: 10px !important;
  }
  
  .rounded-lg {
    border-radius: 30px !important;
  }
  
  .border {
    border: 1px solid #e9ecef !important;
  }
  
  .border-top {
    border-top: 1px solid #e9ecef !important;
  }
  
  .border-bottom {
    border-bottom: 1px solid #e9ecef !important;
  }
  
  .border-left {
    border-left: 1px solid #e9ecef !important;
  }
  
  .border-right {
    border-right: 1px solid #e9ecef !important;
  }
  
  .small, small {
    font-size: 90%;
  }
  
  .card .card-body {
    padding: 1.5rem;
  }
  
  .component-wrapper .title {
    font-size: 24px;
  }
  
  .component-wrapper.sticky-bar li {
    margin-bottom: 6px;
  }
  
  .component-wrapper.sticky-bar li:last-child {
    margin-bottom: 0px !important;
  }
  
  .component-wrapper.sticky-bar li a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .component-wrapper.sticky-bar li a:hover, .component-wrapper.sticky-bar li a:focus {
    color: #2f55d4 !important;
  }
  
  .btn-primary {
    background-color: #2f55d4 !important;
    border: 1px solid #2f55d4 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
            box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  }
  
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary.focus {
    background-color: #2443ac !important;
    border-color: #2443ac !important;
    color: #ffffff !important;
  }
  
  .btn-soft-primary {
    background-color: rgba(47, 85, 212, 0.1) !important;
    border: 1px solid rgba(47, 85, 212, 0.1) !important;
    color: #2f55d4 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
            box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  }
  
  .btn-soft-primary:hover, .btn-soft-primary:focus, .btn-soft-primary:active, .btn-soft-primary.active, .btn-soft-primary.focus {
    background-color: #2f55d4 !important;
    border-color: #2f55d4 !important;
    color: #ffffff !important;
  }
  
  .btn-outline-primary {
    border: 1px solid #2f55d4;
    color: #2f55d4;
    background-color: transparent;
  }
  
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.focus, .btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #2f55d4;
    border-color: #2f55d4;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
            box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  }
  
  .btn-secondary {
    background-color: #5a6d90 !important;
    border: 1px solid #5a6d90 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
            box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  }
  
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .btn-secondary.focus {
    background-color: #465571 !important;
    border-color: #465571 !important;
    color: #ffffff !important;
  }
  
  .btn-soft-secondary {
    background-color: rgba(90, 109, 144, 0.1) !important;
    border: 1px solid rgba(90, 109, 144, 0.1) !important;
    color: #5a6d90 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
            box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  }
  
  .btn-soft-secondary:hover, .btn-soft-secondary:focus, .btn-soft-secondary:active, .btn-soft-secondary.active, .btn-soft-secondary.focus {
    background-color: #5a6d90 !important;
    border-color: #5a6d90 !important;
    color: #ffffff !important;
  }
  
  .btn-outline-secondary {
    border: 1px solid #5a6d90;
    color: #5a6d90;
    background-color: transparent;
  }
  
  .btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.focus, .btn-outline-secondary:not(:disabled):not(.disabled):active {
    background-color: #5a6d90;
    border-color: #5a6d90;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
            box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  }
  
  .btn-success {
    background-color: #2eca8b !important;
    border: 1px solid #2eca8b !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
            box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  }
  
  .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.focus {
    background-color: #25a06e !important;
    border-color: #25a06e !important;
    color: #ffffff !important;
  }
  
  .btn-soft-success {
    background-color: rgba(46, 202, 139, 0.1) !important;
    border: 1px solid rgba(46, 202, 139, 0.1) !important;
    color: #2eca8b !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
            box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  }
  
  .btn-soft-success:hover, .btn-soft-success:focus, .btn-soft-success:active, .btn-soft-success.active, .btn-soft-success.focus {
    background-color: #2eca8b !important;
    border-color: #2eca8b !important;
    color: #ffffff !important;
  }
  
  .btn-outline-success {
    border: 1px solid #2eca8b;
    color: #2eca8b;
    background-color: transparent;
  }
  
  .btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.focus, .btn-outline-success:not(:disabled):not(.disabled):active {
    background-color: #2eca8b;
    border-color: #2eca8b;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
            box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  }
  
  .btn-warning {
    background-color: #f17425 !important;
    border: 1px solid #f17425 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
            box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  }
  
  .btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.focus {
    background-color: #d55b0e !important;
    border-color: #d55b0e !important;
    color: #ffffff !important;
  }
  
  .btn-soft-warning {
    background-color: rgba(241, 116, 37, 0.1) !important;
    border: 1px solid rgba(241, 116, 37, 0.1) !important;
    color: #f17425 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
            box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  }
  
  .btn-soft-warning:hover, .btn-soft-warning:focus, .btn-soft-warning:active, .btn-soft-warning.active, .btn-soft-warning.focus {
    background-color: #f17425 !important;
    border-color: #f17425 !important;
    color: #ffffff !important;
  }
  
  .btn-outline-warning {
    border: 1px solid #f17425;
    color: #f17425;
    background-color: transparent;
  }
  
  .btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.focus, .btn-outline-warning:not(:disabled):not(.disabled):active {
    background-color: #f17425;
    border-color: #f17425;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
            box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  }
  
  .btn-info {
    background-color: #17a2b8 !important;
    border: 1px solid #17a2b8 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
            box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  }
  
  .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.focus {
    background-color: #117a8b !important;
    border-color: #117a8b !important;
    color: #ffffff !important;
  }
  
  .btn-soft-info {
    background-color: rgba(23, 162, 184, 0.1) !important;
    border: 1px solid rgba(23, 162, 184, 0.1) !important;
    color: #17a2b8 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
            box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  }
  
  .btn-soft-info:hover, .btn-soft-info:focus, .btn-soft-info:active, .btn-soft-info.active, .btn-soft-info.focus {
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
    color: #ffffff !important;
  }
  
  .btn-outline-info {
    border: 1px solid #17a2b8;
    color: #17a2b8;
    background-color: transparent;
  }
  
  .btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.focus, .btn-outline-info:not(:disabled):not(.disabled):active {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
            box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  }
  
  .btn-danger {
    background-color: #e43f52 !important;
    border: 1px solid #e43f52 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
            box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  }
  
  .btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger.focus {
    background-color: #d21e32 !important;
    border-color: #d21e32 !important;
    color: #ffffff !important;
  }
  
  .btn-soft-danger {
    background-color: rgba(228, 63, 82, 0.1) !important;
    border: 1px solid rgba(228, 63, 82, 0.1) !important;
    color: #e43f52 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
            box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  }
  
  .btn-soft-danger:hover, .btn-soft-danger:focus, .btn-soft-danger:active, .btn-soft-danger.active, .btn-soft-danger.focus {
    background-color: #e43f52 !important;
    border-color: #e43f52 !important;
    color: #ffffff !important;
  }
  
  .btn-outline-danger {
    border: 1px solid #e43f52;
    color: #e43f52;
    background-color: transparent;
  }
  
  .btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.focus, .btn-outline-danger:not(:disabled):not(.disabled):active {
    background-color: #e43f52;
    border-color: #e43f52;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
            box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  }
  
  .btn-dark {
    background-color: #3c4858 !important;
    border: 1px solid #3c4858 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
            box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  }
  
  .btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark.focus {
    background-color: #272f3a !important;
    border-color: #272f3a !important;
    color: #ffffff !important;
  }
  
  .btn-soft-dark {
    background-color: rgba(60, 72, 88, 0.1) !important;
    border: 1px solid rgba(60, 72, 88, 0.1) !important;
    color: #3c4858 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
            box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  }
  
  .btn-soft-dark:hover, .btn-soft-dark:focus, .btn-soft-dark:active, .btn-soft-dark.active, .btn-soft-dark.focus {
    background-color: #3c4858 !important;
    border-color: #3c4858 !important;
    color: #ffffff !important;
  }
  
  .btn-outline-dark {
    border: 1px solid #3c4858;
    color: #3c4858;
    background-color: transparent;
  }
  
  .btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.focus, .btn-outline-dark:not(:disabled):not(.disabled):active {
    background-color: #3c4858;
    border-color: #3c4858;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
            box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  }
  
  .btn-muted {
    background-color: #8492a6 !important;
    border: 1px solid #8492a6 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
            box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  }
  
  .btn-muted:hover, .btn-muted:focus, .btn-muted:active, .btn-muted.active, .btn-muted.focus {
    background-color: #68788f !important;
    border-color: #68788f !important;
    color: #ffffff !important;
  }
  
  .btn-soft-muted {
    background-color: rgba(132, 146, 166, 0.1) !important;
    border: 1px solid rgba(132, 146, 166, 0.1) !important;
    color: #8492a6 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
            box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  }
  
  .btn-soft-muted:hover, .btn-soft-muted:focus, .btn-soft-muted:active, .btn-soft-muted.active, .btn-soft-muted.focus {
    background-color: #8492a6 !important;
    border-color: #8492a6 !important;
    color: #ffffff !important;
  }
  
  .btn-outline-muted {
    border: 1px solid #8492a6;
    color: #8492a6;
    background-color: transparent;
  }
  
  .btn-outline-muted:hover, .btn-outline-muted:focus, .btn-outline-muted:active, .btn-outline-muted.active, .btn-outline-muted.focus, .btn-outline-muted:not(:disabled):not(.disabled):active {
    background-color: #8492a6;
    border-color: #8492a6;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
            box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  }
  
  .btn-light {
    background-color: #f8f9fc !important;
    border: 1px solid #f8f9fc !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
            box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  }
  
  .btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active, .btn-light.focus {
    background-color: #d4daed !important;
    border-color: #d4daed !important;
    color: #ffffff !important;
  }
  
  .btn-soft-light {
    background-color: rgba(248, 249, 252, 0.1) !important;
    border: 1px solid rgba(248, 249, 252, 0.1) !important;
    color: #f8f9fc !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
            box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  }
  
  .btn-soft-light:hover, .btn-soft-light:focus, .btn-soft-light:active, .btn-soft-light.active, .btn-soft-light.focus {
    background-color: #f8f9fc !important;
    border-color: #f8f9fc !important;
    color: #ffffff !important;
  }
  
  .btn-outline-light {
    border: 1px solid #f8f9fc;
    color: #f8f9fc;
    background-color: transparent;
  }
  
  .btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.focus, .btn-outline-light:not(:disabled):not(.disabled):active {
    background-color: #f8f9fc;
    border-color: #f8f9fc;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
            box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  }
  
  .btn-blue {
    background-color: #2f55d4 !important;
    border: 1px solid #2f55d4 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
            box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  }
  
  .btn-blue:hover, .btn-blue:focus, .btn-blue:active, .btn-blue.active, .btn-blue.focus {
    background-color: #2443ac !important;
    border-color: #2443ac !important;
    color: #ffffff !important;
  }
  
  .btn-soft-blue {
    background-color: rgba(47, 85, 212, 0.1) !important;
    border: 1px solid rgba(47, 85, 212, 0.1) !important;
    color: #2f55d4 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
            box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  }
  
  .btn-soft-blue:hover, .btn-soft-blue:focus, .btn-soft-blue:active, .btn-soft-blue.active, .btn-soft-blue.focus {
    background-color: #2f55d4 !important;
    border-color: #2f55d4 !important;
    color: #ffffff !important;
  }
  
  .btn-outline-blue {
    border: 1px solid #2f55d4;
    color: #2f55d4;
    background-color: transparent;
  }
  
  .btn-outline-blue:hover, .btn-outline-blue:focus, .btn-outline-blue:active, .btn-outline-blue.active, .btn-outline-blue.focus, .btn-outline-blue:not(:disabled):not(.disabled):active {
    background-color: #2f55d4;
    border-color: #2f55d4;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
            box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  }
  
  .btn-footer {
    background-color: #202942 !important;
    border: 1px solid #202942 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
            box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  }
  
  .btn-footer:hover, .btn-footer:focus, .btn-footer:active, .btn-footer.active, .btn-footer.focus {
    background-color: #0f131f !important;
    border-color: #0f131f !important;
    color: #ffffff !important;
  }
  
  .btn-soft-footer {
    background-color: rgba(32, 41, 66, 0.1) !important;
    border: 1px solid rgba(32, 41, 66, 0.1) !important;
    color: #202942 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
            box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  }
  
  .btn-soft-footer:hover, .btn-soft-footer:focus, .btn-soft-footer:active, .btn-soft-footer.active, .btn-soft-footer.focus {
    background-color: #202942 !important;
    border-color: #202942 !important;
    color: #ffffff !important;
  }
  
  .btn-outline-footer {
    border: 1px solid #202942;
    color: #202942;
    background-color: transparent;
  }
  
  .btn-outline-footer:hover, .btn-outline-footer:focus, .btn-outline-footer:active, .btn-outline-footer.active, .btn-outline-footer.focus, .btn-outline-footer:not(:disabled):not(.disabled):active {
    background-color: #202942;
    border-color: #202942;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
            box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  }
  
  .btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 600;
    border-radius: 6px;
  }
  
  .btn:focus {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  
  .btn.btn-sm {
    padding: 7px 16px;
    font-size: 10px;
  }
  
  .btn.btn-lg {
    padding: 14px 30px;
    font-size: 16px;
  }
  
  .btn.searchbtn {
    padding: 6px 20px;
  }
  
  .btn.btn-pills {
    border-radius: 30px;
  }
  
  .btn.btn-light {
    color: #3c4858 !important;
    border: 1px solid #dee2e6 !important;
  }
  
  .btn.btn-light:hover, .btn.btn-light:focus, .btn.btn-light:active, .btn.btn-light.active, .btn.btn-light.focus {
    background-color: #d4daed !important;
    color: #3c4858 !important;
  }
  
  .btn.btn-soft-light {
    color: rgba(60, 72, 88, 0.5) !important;
    border: 1px solid #dee2e6 !important;
  }
  
  .btn.btn-soft-light:hover, .btn.btn-soft-light:focus, .btn.btn-soft-light:active, .btn.btn-soft-light.active, .btn.btn-soft-light.focus {
    color: #3c4858 !important;
  }
  
  .btn.btn-outline-light {
    border: 1px solid #dee2e6 !important;
    color: #3c4858 !important;
    background-color: transparent;
  }
  
  .btn.btn-outline-light:hover, .btn.btn-outline-light:focus, .btn.btn-outline-light:active, .btn.btn-outline-light.active, .btn.btn-outline-light.focus {
    background-color: #f8f9fc !important;
  }
  
  .btn.btn-icon {
    height: 36px;
    width: 36px;
    line-height: 34px;
    padding: 0;
  }
  
  .btn.btn-icon .icons {
    height: 16px;
    width: 16px;
    font-size: 16px;
  }
  
  .btn.btn-icon.btn-lg {
    height: 48px;
    width: 48px;
    line-height: 46px;
  }
  
  .btn.btn-icon.btn-lg .icons {
    height: 20px;
    width: 20px;
    font-size: 20px;
  }
  
  .btn.btn-icon.btn-sm {
    height: 30px;
    width: 30px;
    line-height: 28px;
  }
  
  button:not(:disabled) {
    outline: none;
  }
  
  .shadow {
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
            box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
  }
  
  .shadow-lg {
    -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
            box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
  }
  
  .shadow-md {
    -webkit-box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
            box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
  }
  
  .badge {
    letter-spacing: 0.5px;
    padding: 4px 8px;
    font-weight: 600;
    line-height: 11px;
  }
  
  .badge.badge-link:hover {
    color: #ffffff !important;
  }
  
  .dropdown-primary .dropdown-menu .dropdown-item:hover, .dropdown-primary .dropdown-menu .dropdown-item.active, .dropdown-primary .dropdown-menu .dropdown-item:active, .dropdown-primary .dropdown-menu .dropdown-item.focus, .dropdown-primary .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #2f55d4 !important;
  }
  
  .dropdown-secondary .dropdown-menu .dropdown-item:hover, .dropdown-secondary .dropdown-menu .dropdown-item.active, .dropdown-secondary .dropdown-menu .dropdown-item:active, .dropdown-secondary .dropdown-menu .dropdown-item.focus, .dropdown-secondary .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #5a6d90 !important;
  }
  
  .dropdown-success .dropdown-menu .dropdown-item:hover, .dropdown-success .dropdown-menu .dropdown-item.active, .dropdown-success .dropdown-menu .dropdown-item:active, .dropdown-success .dropdown-menu .dropdown-item.focus, .dropdown-success .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #2eca8b !important;
  }
  
  .dropdown-warning .dropdown-menu .dropdown-item:hover, .dropdown-warning .dropdown-menu .dropdown-item.active, .dropdown-warning .dropdown-menu .dropdown-item:active, .dropdown-warning .dropdown-menu .dropdown-item.focus, .dropdown-warning .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #f17425 !important;
  }
  
  .dropdown-info .dropdown-menu .dropdown-item:hover, .dropdown-info .dropdown-menu .dropdown-item.active, .dropdown-info .dropdown-menu .dropdown-item:active, .dropdown-info .dropdown-menu .dropdown-item.focus, .dropdown-info .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #17a2b8 !important;
  }
  
  .dropdown-danger .dropdown-menu .dropdown-item:hover, .dropdown-danger .dropdown-menu .dropdown-item.active, .dropdown-danger .dropdown-menu .dropdown-item:active, .dropdown-danger .dropdown-menu .dropdown-item.focus, .dropdown-danger .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #e43f52 !important;
  }
  
  .dropdown-dark .dropdown-menu .dropdown-item:hover, .dropdown-dark .dropdown-menu .dropdown-item.active, .dropdown-dark .dropdown-menu .dropdown-item:active, .dropdown-dark .dropdown-menu .dropdown-item.focus, .dropdown-dark .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #3c4858 !important;
  }
  
  .dropdown-muted .dropdown-menu .dropdown-item:hover, .dropdown-muted .dropdown-menu .dropdown-item.active, .dropdown-muted .dropdown-menu .dropdown-item:active, .dropdown-muted .dropdown-menu .dropdown-item.focus, .dropdown-muted .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #8492a6 !important;
  }
  
  .dropdown-light .dropdown-menu .dropdown-item:hover, .dropdown-light .dropdown-menu .dropdown-item.active, .dropdown-light .dropdown-menu .dropdown-item:active, .dropdown-light .dropdown-menu .dropdown-item.focus, .dropdown-light .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #f8f9fc !important;
  }
  
  .dropdown-blue .dropdown-menu .dropdown-item:hover, .dropdown-blue .dropdown-menu .dropdown-item.active, .dropdown-blue .dropdown-menu .dropdown-item:active, .dropdown-blue .dropdown-menu .dropdown-item.focus, .dropdown-blue .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #2f55d4 !important;
  }
  
  .dropdown-footer .dropdown-menu .dropdown-item:hover, .dropdown-footer .dropdown-menu .dropdown-item.active, .dropdown-footer .dropdown-menu .dropdown-item:active, .dropdown-footer .dropdown-menu .dropdown-item.focus, .dropdown-footer .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    color: #202942 !important;
  }
  
  .btn-group .dropdown-toggle:after {
    content: "";
    position: relative;
    right: -4px;
    top: -2px;
    border: solid #ffffff;
    border-radius: 0.5px;
    border-width: 0 2px 2px 0;
    padding: 3px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin-left: 0;
    vertical-align: 0;
  }
  
  .btn-group .dropdown-menu {
    margin-top: 10px;
    border: 0;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
            box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  
  .btn-group .dropdown-menu:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 45px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 7px solid #495057;
    border-radius: 0.5px;
    border-color: transparent transparent #ffffff #ffffff;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    -webkit-box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, 0.15);
            box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, 0.15);
  }
  
  .alert-primary {
    background-color: rgba(47, 85, 212, 0.9);
    color: #ffffff;
    border-color: #2f55d4;
  }
  
  .alert-primary .alert-link {
    color: #122258;
  }
  
  .alert-outline-primary {
    background-color: #ffffff;
    color: #2f55d4;
    border-color: #2f55d4;
  }
  
  .alert-secondary {
    background-color: rgba(90, 109, 144, 0.9);
    color: #ffffff;
    border-color: #5a6d90;
  }
  
  .alert-secondary .alert-link {
    color: #1f2632;
  }
  
  .alert-outline-secondary {
    background-color: #ffffff;
    color: #5a6d90;
    border-color: #5a6d90;
  }
  
  .alert-success {
    background-color: rgba(46, 202, 139, 0.9);
    color: #ffffff;
    border-color: #2eca8b;
  }
  
  .alert-success .alert-link {
    color: #124d35;
  }
  
  .alert-outline-success {
    background-color: #ffffff;
    color: #2eca8b;
    border-color: #2eca8b;
  }
  
  .alert-warning {
    background-color: rgba(241, 116, 37, 0.9);
    color: #ffffff;
    border-color: #f17425;
  }
  
  .alert-warning .alert-link {
    color: #753208;
  }
  
  .alert-outline-warning {
    background-color: #ffffff;
    color: #f17425;
    border-color: #f17425;
  }
  
  .alert-info {
    background-color: rgba(23, 162, 184, 0.9);
    color: #ffffff;
    border-color: #17a2b8;
  }
  
  .alert-info .alert-link {
    color: #062a30;
  }
  
  .alert-outline-info {
    background-color: #ffffff;
    color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .alert-danger {
    background-color: rgba(228, 63, 82, 0.9);
    color: #ffffff;
    border-color: #e43f52;
  }
  
  .alert-danger .alert-link {
    color: #79111d;
  }
  
  .alert-outline-danger {
    background-color: #ffffff;
    color: #e43f52;
    border-color: #e43f52;
  }
  
  .alert-dark {
    background-color: rgba(60, 72, 88, 0.9);
    color: #ffffff;
    border-color: #3c4858;
  }
  
  .alert-dark .alert-link {
    color: black;
  }
  
  .alert-outline-dark {
    background-color: #ffffff;
    color: #3c4858;
    border-color: #3c4858;
  }
  
  .alert-muted {
    background-color: rgba(132, 146, 166, 0.9);
    color: #ffffff;
    border-color: #8492a6;
  }
  
  .alert-muted .alert-link {
    color: #3d4654;
  }
  
  .alert-outline-muted {
    background-color: #ffffff;
    color: #8492a6;
    border-color: #8492a6;
  }
  
  .alert-light {
    background-color: rgba(248, 249, 252, 0.9);
    color: #ffffff;
    border-color: #f8f9fc;
  }
  
  .alert-light .alert-link {
    color: #8d9dce;
  }
  
  .alert-outline-light {
    background-color: #ffffff;
    color: #f8f9fc;
    border-color: #f8f9fc;
  }
  
  .alert-blue {
    background-color: rgba(47, 85, 212, 0.9);
    color: #ffffff;
    border-color: #2f55d4;
  }
  
  .alert-blue .alert-link {
    color: #122258;
  }
  
  .alert-outline-blue {
    background-color: #ffffff;
    color: #2f55d4;
    border-color: #2f55d4;
  }
  
  .alert-footer {
    background-color: rgba(32, 41, 66, 0.9);
    color: #ffffff;
    border-color: #202942;
  }
  
  .alert-footer .alert-link {
    color: black;
  }
  
  .alert-outline-footer {
    background-color: #ffffff;
    color: #202942;
    border-color: #202942;
  }
  
  .alert {
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .alert.alert-light {
    background-color: white;
    color: #3c4858;
    border-color: #e9ecef;
  }
  
  .alert.alert-dismissible {
    padding-right: 30px;
  }
  
  .alert.alert-dismissible .btn-close {
    top: 10px;
    right: 10px;
    padding: 0px;
  }
  
  .alert.alert-pills {
    border-radius: 30px;
    display: inline-block;
  }
  
  .alert.alert-pills .content {
    font-weight: 600;
  }
  
  .page-next-level .title {
    font-size: 28px;
    letter-spacing: 1px;
  }
  
  .page-next-level .page-next {
    position: relative;
    top: 110px;
    z-index: 99;
  }
  
  @media (max-width: 768px) {
    .page-next-level .page-next {
      top: 70px;
    }
  }
  
  .breadcrumb {
    letter-spacing: 0.5px;
    padding: 8px 24px;
    display: block;
  }
  
  .breadcrumb .breadcrumb-item {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding-left: 0;
    display: inline-block;
  }
  
  .breadcrumb .breadcrumb-item a {
    color: #3c4858;
  }
  
  .breadcrumb .breadcrumb-item a:hover {
    color: #2f55d4;
  }
  
  .breadcrumb .breadcrumb-item.active {
    color: #2f55d4;
  }
  
  .breadcrumb .breadcrumb-item:before {
    content: "";
  }
  
  .breadcrumb .breadcrumb-item:after {
    content: "\F0142" !important;
    font-size: 14px;
    color: #3c4858;
    font-family: 'Material Design Icons';
    padding-left: 5px;
  }
  
  .breadcrumb .breadcrumb-item:last-child:after {
    display: none;
  }
  
  .pagination .page-item:first-child .page-link {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  
  .pagination .page-item:last-child .page-link {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  
  .pagination .page-item .page-link {
    color: #3c4858;
    border: 1px solid #dee2e6;
    padding: 8px 16px;
    font-size: 14px;
  }
  
  .pagination .page-item .page-link:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .pagination .page-item .page-link:hover {
    color: #ffffff;
    background: rgba(47, 85, 212, 0.9);
    border-color: rgba(47, 85, 212, 0.9);
  }
  
  .pagination .page-item.active .page-link {
    color: #ffffff;
    background: #2f55d4 !important;
    border-color: #2f55d4;
    cursor: not-allowed;
  }
  
  .avatar.avatar-ex-sm {
    max-height: 25px;
  }
  
  .avatar.avatar-md-sm {
    height: 45px;
    width: 45px;
  }
  
  .avatar.avatar-small {
    height: 65px;
    width: 65px;
  }
  
  .avatar.avatar-md-md {
    height: 80px;
    width: 80px;
  }
  
  .avatar.avatar-medium {
    height: 110px;
    width: 110px;
  }
  
  .avatar.avatar-large {
    height: 140px;
    width: 140px;
  }
  
  .avatar.avatar-ex-large {
    height: 180px;
    width: 180px;
  }
  
  .accordion .accordion-item {
    overflow: hidden;
  }
  
  .accordion .accordion-item .accordion-button {
    font-weight: 600;
    text-align: left;
  }
  
  .accordion .accordion-item .accordion-button:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .accordion .accordion-item .accordion-button:before {
    content: '\F0143';
    display: block;
    font-family: 'Material Design Icons';
    font-size: 16px;
    color: #2f55d4;
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .accordion .accordion-item .accordion-button.collapsed {
    background-color: #ffffff;
    color: #3c4858 !important;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  
  .accordion .accordion-item .accordion-button.collapsed:before {
    top: 15px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    color: #3c4858 !important;
  }
  
  .accordion .accordion-item .accordion-button:after {
    display: none;
  }
  
  .nav-pills {
    margin-bottom: 15px;
    background: #f4f6fa;
  }
  
  .nav-pills .nav-link {
    color: #495057 !important;
    padding: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .nav-pills .nav-link.nav-link-alt {
    color: #ffffff !important;
  }
  
  .nav-pills .nav-link.nav-link-alt.active {
    background: #3c4858 !important;
  }
  
  .nav-pills .nav-link.active {
    background: #2f55d4;
    color: #ffffff !important;
  }
  
  .nav-pills .nav-link.active .tab-para {
    color: rgba(255, 255, 255, 0.65) !important;
  }
  
  .progress-box .title {
    font-size: 15px;
  }
  
  .progress-box .progress {
    height: 8px;
    overflow: visible;
  }
  
  .progress-box .progress .progress-bar {
    border-radius: 6px;
    -webkit-animation: animate-positive 3s;
            animation: animate-positive 3s;
    overflow: visible !important;
  }
  
  .progress-box .progress .progress-value {
    position: absolute;
    top: -32px;
    right: -15px;
    font-size: 13px;
  }
  
  @-webkit-keyframes animate-positive {
    0% {
      width: 0;
    }
  }
  
  @keyframes animate-positive {
    0% {
      width: 0;
    }
  }
  
  .blockquote {
    border-left: 3px solid #dee2e6;
    border-radius: 6px;
    font-size: 16px;
  }
  
  .form-label,
  .form-check-label {
    font-weight: 700;
    font-size: 14px;
  }
  
  .form-control {
    border: 1px solid #dee2e6;
    font-size: 14px;
    line-height: 26px;
    background-color: #ffffff;
    border-radius: 6px;
  }
  
  .form-control:focus {
    border-color: #2f55d4;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .form-control[readonly] {
    background-color: #ffffff;
  }
  
  .form-control:disabled {
    background-color: #dee2e6;
  }
  
  .form-check-input:focus {
    border-color: #2f55d4;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .form-check-input.form-check-input:checked {
    background-color: #2f55d4;
    border-color: #2f55d4;
  }
  
  .subcribe-form .btn {
    padding: 10px 20px;
  }
  
  .subcribe-form input {
    padding: 12px 20px;
    width: 100%;
    color: #3c4858 !important;
    border: none;
    outline: none !important;
    padding-right: 160px;
    padding-left: 30px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .subcribe-form button {
    position: absolute;
    top: 1.5px;
    right: 2px;
    outline: none !important;
  }
  
  .subcribe-form form {
    position: relative;
    max-width: 600px;
    margin: 0px auto;
  }
  
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
  
  .table-responsive .table td,
  .table-responsive .table th {
    vertical-align: middle;
  }
  
  .table-responsive .table.table-nowrap th,
  .table-responsive .table.table-nowrap td {
    white-space: nowrap;
  }
  
  .table-responsive .table-center th {
    vertical-align: middle !important;
  }
  
  .table-responsive .table-center tbody tr:hover {
    color: #3c4858;
    background-color: #f8f9fa;
  }
  
  .table-responsive .table-center.invoice-tb th,
  .table-responsive .table-center.invoice-tb td {
    text-align: end;
  }
  
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, #ffffff, #ffffff);
    z-index: 9999999;
  }
  
  #preloader #status {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  
  #preloader #status .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
  }
  
  #preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #2f55d4;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
            animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  #preloader #status .spinner .double-bounce2 {
    -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes sk-bounce {
    0%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  .social-icon li a {
    color: #3c4858;
    border: 1px solid #3c4858;
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 28px;
    text-align: center;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    overflow: hidden;
    position: relative;
  }
  
  .social-icon li a .fea-social {
    stroke-width: 2;
  }
  
  .social-icon li a:hover {
    background-color: #2f55d4;
    border-color: #2f55d4 !important;
    color: #ffffff !important;
  }
  
  .social-icon li a:hover .fea-social {
    fill: #2f55d4;
  }
  
  .social-icon.social li a {
    color: #adb5bd;
    border-color: #adb5bd;
  }
  
  .social-icon.foot-social-icon li a {
    color: #adb5bd;
    border-color: #283353;
  }
  
  .back-to-top {
    z-index: 99;
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: none;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .back-to-top .icons {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .back-to-top:hover {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  .back-to-top:hover .icons {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  .back-to-home {
    position: fixed;
    top: 4%;
    right: 2%;
    z-index: 1;
  }
  
   
  .fea {
    stroke-width: 1.8;
  }
  
  .fea.icon-sm {
    height: 16px;
    width: 16px;
  }
  
  .fea.icon-ex-md {
    height: 20px;
    width: 20px;
  }
  
  .fea.icon-m-md {
    height: 28px;
    width: 28px;
  }
  
  .fea.icon-md {
    height: 35px;
    width: 35px;
  }
  
  .fea.icon-lg {
    height: 42px;
    width: 42px;
  }
  
  .fea.icon-ex-lg {
    height: 52px;
    width: 52px;
  }
  
  [class^=uil-]:before,
  [class*=" uil-"]:before {
    margin: 0;
  }
  
  .uim-svg {
    vertical-align: 0 !important;
  }
  
  @media (max-width: 768px) {
    .display-1 {
      font-size: 56px !important;
    }
    .display-2 {
      font-size: 50px !important;
    }
    .display-3 {
      font-size: 42px !important;
    }
    .display-4 {
      font-size: 36px !important;
    }
  }
  
  body {
    font-family: "Nunito", sans-serif;
    overflow-x: hidden !important;
    font-size: 16px;
    color: #161c2d;
  }
  
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Nunito", sans-serif;
    line-height: 1.5;
    font-weight: 600;
  }
  
  ::-moz-selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
  }
  
  ::selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
  }
  
  a {
    text-decoration: none !important;
  }
  
  p {
    line-height: 1.6;
  }
  
  .section {
    padding: 100px 0;
    position: relative;
  }
  
  @media (max-width: 768px) {
    .section {
      padding: 60px 0;
    }
  }
  
  .section-two {
    padding: 60px 0;
    position: relative;
  }
  
  .bg-overlay {
    background-color: rgba(60, 72, 88, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  
  .bg-overlay-white {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .title-heading {
    line-height: 26px;
  }
  
  .title-heading .heading {
    font-size: 45px !important;
    letter-spacing: 1px;
  }
  
  @media (max-width: 768px) {
    .title-heading .heading {
      font-size: 35px !important;
    }
  }
  
  .title-heading .para-desc {
    font-size: 18px;
  }
  
  .section-title .title {
    letter-spacing: 0.5px;
    font-size: 30px !important;
  }
  
  @media (max-width: 768px) {
    .section-title .title {
      font-size: 24px !important;
    }
  }
  
  .text-shadow-title {
    text-shadow: 2px 0 0 #ffffff, -2px 0 0 #ffffff, 0 4px 0 rgba(255, 255, 255, 0.4), 0 -2px 0 #ffffff, 1px 1px #ffffff, -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff;
  }
  
  .para-desc {
    max-width: 600px;
  }
  
  .mt-100 {
    margin-top: 100px;
  }
  
  .shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -2px;
    left: 0;
  }
  
  .shape > svg {
    -webkit-transform: scale(2);
            transform: scale(2);
    width: 100%;
    height: auto;
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
  
  .shape.integration-hero {
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    height: 250px;
    background: #ffffff;
  }
  
  @media (max-width: 768px) {
    .shape.integration-hero {
      height: 140px;
    }
  }
  
  @media (max-width: 425px) {
    .shape.integration-hero {
      height: 60px;
    }
  }
  
  @media (max-width: 425px) {
    .shape {
      bottom: -4px;
    }
  }
  
  .text-slider {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  
  .text-slider > ul {
    white-space: nowrap;
  }
  
  .text-slider .move-text {
    -webkit-animation: moveSlideshow 12s linear infinite;
            animation: moveSlideshow 12s linear infinite;
  }
  
  @-webkit-keyframes moveSlideshow {
    100% {
      -webkit-transform: translateX(-45%);
              transform: translateX(-45%);
    }
  }
  
  @keyframes moveSlideshow {
    100% {
      -webkit-transform: translateX(-45%);
              transform: translateX(-45%);
    }
  }
  
  .mover {
    -webkit-animation: mover 1s infinite alternate;
            animation: mover 1s infinite alternate;
  }
  
  @-webkit-keyframes mover {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
    }
  }
  
  @keyframes mover {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
    }
  }
  
  .feature-posts-placeholder {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 66.6%;
  }
  
  @media (max-width: 425px) {
    .feature-posts-placeholder {
      height: 80%;
    }
  }
  
  .spin-anything {
    -webkit-animation: spinner-animation 5s linear infinite;
            animation: spinner-animation 5s linear infinite;
  }
  
  @-webkit-keyframes spinner-animation {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes spinner-animation {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .seo-hero-widget {
    position: relative;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .seo-hero-widget:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    z-index: 9;
  }
  
  .home-wrapper:before {
    content: "";
    position: absolute;
    bottom: 40rem;
    right: 0rem;
    width: 90rem;
    height: 35rem;
    -webkit-transform: rotate(78.75deg);
            transform: rotate(78.75deg);
    background: -webkit-gradient(linear, left top, right top, from(rgba(47, 85, 212, 0.3)), to(transparent));
    background: linear-gradient(90deg, rgba(47, 85, 212, 0.3), transparent);
    z-index: 1;
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .home-wrapper:before {
      right: -20rem;
    }
  }
  
  @media (min-width: 426px) and (max-width: 768px) {
    .home-wrapper:before {
      right: -15rem;
      width: 70rem;
      height: 25rem;
    }
  }
  
  @media (max-width: 425px) {
    .home-wrapper:before {
      right: -25rem;
    }
  }
  
  @media (min-width: 768px) {
    .margin-top-100 {
      margin-top: 100px;
    }
  }
  
  @media (max-width: 768px) {
    .mt-60 {
      margin-top: 60px;
    }
  }
  
  #topnav {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: transparent;
    border: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
  }
  
  #topnav .logo {
    float: left;
    color: #3c4858 !important;
  }
  
  #topnav .logo .l-dark,
  #topnav .logo .logo-dark-mode {
    display: none;
  }
  
  #topnav .logo .l-light,
  #topnav .logo .logo-light-mode {
    display: inline-block;
  }
  
  #topnav .logo:focus {
    outline: none;
  }
  
  #topnav .has-submenu.active a {
    color: #ffffff;
  }
  
  #topnav .has-submenu.active .submenu li.active > a {
    color: #2f55d4 !important;
  }
  
  #topnav .has-submenu.active.active .menu-arrow {
    border-color: #2f55d4;
  }
  
  #topnav .has-submenu {
    position: relative;
  }
  
  #topnav .has-submenu .submenu .submenu-arrow {
    border: solid #3c4858;
    border-radius: 0.5px;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: absolute;
    right: 20px;
    top: 14px;
  }
  
  #topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow {
    border-color: #2f55d4;
  }
  
  #topnav .navbar-toggle {
    border: 0;
    position: relative;
    width: 60px;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  
  #topnav .navbar-toggle .lines {
    width: 25px;
    display: block;
    position: relative;
    margin: 28px 20px;
    height: 18px;
  }
  
  #topnav .navbar-toggle span {
    height: 2px;
    width: 100%;
    background-color: #3c4858;
    display: block;
    margin-bottom: 5px;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
  }
  
  #topnav .navbar-toggle span:last-child {
    margin-bottom: 0;
  }
  
  #topnav .buy-button {
    float: right;
    line-height: 68px;
  }
  
  #topnav .buy-button > li {
    line-height: initial;
  }
  
  #topnav .buy-button .login-btn-primary {
    display: none;
  }
  
  #topnav .buy-button .login-btn-light {
    display: inline-block;
  }
  
  #topnav .buy-button .dropdown .dropdown-toggle:after,
  #topnav .buy-menu-btn .dropdown .dropdown-toggle:after {
    display: none;
  }
  
  #topnav .navbar-toggle.open span {
    position: absolute;
  }
  
  #topnav .navbar-toggle.open span:first-child {
    top: 6px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  #topnav .navbar-toggle.open span:nth-child(2) {
    visibility: hidden;
  }
  
  #topnav .navbar-toggle.open span:last-child {
    width: 100%;
    top: 6px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  #topnav .navbar-toggle.open span:hover {
    background-color: #2f55d4;
  }
  
  #topnav .navbar-toggle:hover, #topnav .navbar-toggle:focus,
  #topnav .navbar-toggle .navigation-menu > li > a:hover, #topnav .navbar-toggle:focus {
    background-color: transparent;
  }
  
  #topnav .navigation-menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  #topnav .navigation-menu > li {
    float: left;
    display: block;
    position: relative;
    margin: 0 10px;
  }
  
  #topnav .navigation-menu > li:hover > a,
  #topnav .navigation-menu > li.active > a {
    color: #2f55d4 !important;
  }
  
  #topnav .navigation-menu > li > a {
    display: block;
    color: #3c4858;
    font-size: 13px;
    background-color: transparent !important;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    font-family: "Nunito", sans-serif;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  #topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li > a:active {
    color: #2f55d4;
  }
  
  #topnav .navigation-menu .has-submenu .menu-arrow {
    border: solid #3c4858;
    border-radius: 0.5px;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    right: -1px;
    top: 30px;
  }
  
  #topnav .navigation-menu .has-submenu:hover .menu-arrow {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
  }
  
  #topnav .menu-extras {
    float: right;
  }
  
  #topnav.scroll {
    background-color: #ffffff;
    border: none;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
            box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  
  #topnav.scroll .navigation-menu > li > a {
    color: #3c4858;
  }
  
  #topnav.scroll .navigation-menu > li > .menu-arrow {
    border-color: #3c4858;
  }
  
  #topnav.scroll .navigation-menu > li:hover > a, #topnav.scroll .navigation-menu > li.active > a {
    color: #2f55d4;
  }
  
  #topnav.scroll .navigation-menu > li:hover > .menu-arrow, #topnav.scroll .navigation-menu > li.active > .menu-arrow {
    border-color: #2f55d4;
  }
  
  #topnav.defaultscroll.dark-menubar .logo {
    line-height: 70px;
  }
  
  #topnav.defaultscroll.scroll .logo {
    line-height: 62px;
  }
  
  #topnav.defaultscroll.scroll.dark-menubar .logo {
    line-height: 62px;
  }
  
  #topnav.nav-sticky {
    background: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
            box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  
  #topnav.nav-sticky .navigation-menu.nav-light > li > a {
    color: #3c4858;
  }
  
  #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: #2f55d4 !important;
  }
  
  #topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
    border-color: #2f55d4 !important;
  }
  
  #topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: #2f55d4 !important;
  }
  
  #topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: #3c4858;
  }
  
  #topnav.nav-sticky.tagline-height {
    top: 0 !important;
  }
  
  #topnav.nav-sticky .buy-button .login-btn-primary {
    display: inline-block;
  }
  
  #topnav.nav-sticky .buy-button .login-btn-light {
    display: none;
  }
  
  #topnav.nav-sticky .logo .l-dark {
    display: inline-block;
  }
  
  #topnav.nav-sticky .logo .l-light {
    display: none;
  }
  
  .logo {
    font-weight: 700;
    font-size: 24px;
    padding: 0 0 6px;
    letter-spacing: 1px;
    line-height: 68px;
  }
  
  @media (min-width: 1025px) {
    #topnav .navigation-menu > li .submenu.megamenu {
      width: 1116px !important;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li {
      min-width: 223px !important;
    }
  }
  
  @media screen and (max-width: 1024px) and (min-width: 992px) {
    #topnav .navigation-menu > li .submenu.megamenu {
      width: 936PX !important;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li {
      min-width: 180px;
    }
  }
  
  @media (min-width: 992px) {
    #topnav .navigation-menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-content: center;
    }
    #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
      top: 33px !important;
    }
    #topnav .navigation-menu > .has-submenu.active .menu-arrow {
      top: 30px;
    }
    #topnav .navigation-menu > li .submenu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      padding: 15px 0;
      list-style: none;
      min-width: 180px;
      visibility: hidden;
      opacity: 0;
      margin-top: 10px;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      border-radius: 6px;
      background-color: #ffffff;
      -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
              box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    }
    #topnav .navigation-menu > li .submenu li {
      position: relative;
    }
    #topnav .navigation-menu > li .submenu li a {
      display: block;
      padding: 10px 20px;
      clear: both;
      white-space: nowrap;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      font-weight: 700;
      color: #3c4858 !important;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    #topnav .navigation-menu > li .submenu li a:hover {
      color: #2f55d4 !important;
    }
    #topnav .navigation-menu > li .submenu li ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }
    #topnav .navigation-menu > li .submenu.megamenu {
      white-space: nowrap;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      position: fixed;
      top: 74px;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li {
      overflow: hidden;
      display: inline-block;
      vertical-align: top;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
      left: 100%;
      top: 0;
      margin-left: 10px;
      margin-top: -1px;
    }
    #topnav .navigation-menu > li .submenu > li .submenu {
      left: 101%;
      top: 0;
      margin-left: 10px;
      margin-top: -1px;
    }
    #topnav .navigation-menu > li > a {
      padding-top: 25px;
      padding-bottom: 25px;
      min-height: 62px;
    }
    #topnav .navigation-menu > li:hover > .menu-arrow {
      border-color: #2f55d4;
    }
    #topnav .navigation-menu > li:hover > a,
    #topnav .navigation-menu > li.active > a {
      color: #2f55d4 !important;
    }
    #topnav .navigation-menu > li.last-elements .submenu {
      left: auto;
      right: 0;
    }
    #topnav .navigation-menu > li.last-elements .submenu:before {
      left: auto;
      right: 10px;
    }
    #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
      left: auto;
      right: 100%;
      margin-left: 0;
      margin-right: 10px;
    }
    #topnav .navigation-menu.nav-light > li > a {
      color: rgba(255, 255, 255, 0.5);
    }
    #topnav .navigation-menu.nav-light > li.active > a {
      color: #ffffff !important;
    }
    #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
      border-color: #ffffff !important;
    }
    #topnav .navigation-menu.nav-light > li:hover > a {
      color: #ffffff !important;
    }
    #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
      border-color: rgba(255, 255, 255, 0.5);
    }
    #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
      border-color: #ffffff !important;
    }
 
    #topnav .navigation-menu.nav-left > li.last-elements .submenu {
      left: 0 !important;
      right: auto !important;
    }
    #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
      left: 45px !important;
      right: auto !important;
    }
    #topnav .buy-button {
      padding-left: 15px;
      margin-left: 15px;
    }
    #topnav .navbar-toggle {
      display: none;
    }
    #topnav #navigation {
      display: block !important;
    }
    #topnav.scroll {
      top: 0;
    }
    #topnav.scroll .navigation-menu > li > a {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    #topnav.scroll-active .navigation-menu > li > a {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  
  @media (max-width: 991px) {
    #topnav {
      background-color: #ffffff;
      -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
              box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
      min-height: 74px;
    }
    #topnav .logo .l-dark {
      display: inline-block !important;
    }
    #topnav .logo .l-light {
      display: none !important;
    }
    #topnav .container {
      width: auto;
    }
    #topnav #navigation {
      max-height: 400px;
    }
    #topnav .navigation-menu {
      float: none;
    }
    #topnav .navigation-menu > li {
      float: none;
    }
    #topnav .navigation-menu > li .submenu {
      display: none;
      list-style: none;
      padding-left: 20px;
      margin: 0;
    }
    #topnav .navigation-menu > li .submenu li a {
      display: block;
      position: relative;
      padding: 7px 15px;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.04em;
      font-weight: 700;
      color: #3c4858 !important;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    #topnav .navigation-menu > li .submenu.open {
      display: block;
    }
    #topnav .navigation-menu > li .submenu .submenu {
      display: none;
      list-style: none;
    }
    #topnav .navigation-menu > li .submenu .submenu.open {
      display: block;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li > ul {
      list-style: none;
      padding-left: 0;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
      display: block;
      position: relative;
      padding: 10px 15px;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 2px;
      color: #8492a6;
    }
    #topnav .navigation-menu > li > a {
      color: #3c4858;
      padding: 10px 20px;
    }
    #topnav .navigation-menu > li > a:after {
      position: absolute;
      right: 15px;
    }
    #topnav .navigation-menu > li > a:hover,
    #topnav .navigation-menu > li .submenu li a:hover,
    #topnav .navigation-menu > li.has-submenu.open > a {
      color: #2f55d4;
    }
    #topnav .menu-extras .menu-item {
      border-color: #8492a6;
    }
    #topnav .navbar-header {
      float: left;
    }
    #topnav .buy-button .login-btn-primary {
      display: inline-block !important;
    }
    #topnav .buy-button .login-btn-light {
      display: none;
    }
    #topnav .has-submenu .submenu .submenu-arrow {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      position: absolute;
      right: 20px;
      top: 12px;
    }
    #topnav .has-submenu.active a {
      color: #2f55d4;
    }
    #navigation {
      position: absolute;
      top: 74px;
      left: 0;
      width: 100%;
      display: none;
      height: auto;
      padding-bottom: 0;
      overflow: auto;
      border-top: 1px solid #f1f3f9;
      border-bottom: 1px solid #f1f3f9;
      background-color: #ffffff;
    }
    #navigation.open {
      display: block;
      overflow-y: auto;
    }
  }
  
  @media (max-width: 768px) {
    #topnav .navigation-menu .has-submenu .menu-arrow {
      right: 16px;
      top: 16px;
    }
    #topnav .buy-button {
      padding: 3px 0;
    }
  }
  
  @media (min-width: 768px) {
    #topnav .navigation-menu > li.has-submenu:hover > .submenu {
      visibility: visible;
      opacity: 1;
      margin-top: 0;
    }
    #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
      visibility: visible;
      opacity: 1;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-toggle {
      display: block;
    }
  }
  
  @media (max-width: 375px) {
    #topnav .buy-button {
      display: none;
    }
    #topnav .buy-menu-btn {
      display: block !important;
      margin: 0 10px;
      padding: 10px 20px;
    }
    #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
      -webkit-transform: translate3d(0px, -54px, 0px) !important;
              transform: translate3d(0px, -54px, 0px) !important;
    }
    #topnav .navbar-toggle .lines {
      margin-right: 0 !important;
    }
  }
  
  .tagline {
    position: absolute;
    width: 100%;
    z-index: 99;
    font-size: 14px;
    padding: 13px 0;
  }
  
  @media screen and (max-width: 575px) {
    .tagline {
      display: none;
    }
  }
  
  @media screen and (max-width: 575px) {
    .tagline-height {
      top: 0px !important;
    }
  }
  
  @media (min-width: 576px) {
    .tagline-height {
      top: 47px !important;
    }
  }
  
  .sidebar-nav {
    padding: 15px 0;
  }
  
  .sidebar-nav > .navbar-item {
    padding: 3px 15px;
  }
  
  .sidebar-nav > .navbar-item .navbar-link {
    color: #3c4858 !important;
    font-size: 15px;
    font-weight: 600;
  }
  
  .sidebar-nav > .navbar-item .navbar-link .navbar-icon {
    font-size: 18px;
    margin-right: 6px;
  }
  
  #navmenu-nav li.active a {
    color: #2f55d4;
  }
  
  #navmenu-nav li.account-menu.active .navbar-link, #navmenu-nav li.account-menu:hover .navbar-link {
    color: #ffffff !important;
    background-color: #2f55d4 !important;
  }
  
  .bg-home {
    height: 100vh;
    background-size: cover;
    position: relative;
    background-position: center center;
  }
  
  .bg-half-260 {
    padding: 260px 0;
    background-size: cover;
    position: relative;
    background-position: center center;
  }
  
  .bg-half-170 {
    padding: 170px 0;
    background-size: cover;
    position: relative;
    background-position: center center;
  }
  
  .bg-half {
    padding: 184px 0 90px;
    background-size: cover;
    position: relative;
    background-position: center center;
  }
  
  .bg-auth-home {
    padding: 142px 0;
    background-size: cover;
    position: relative;
    background-position: center center;
  }
  
  .bg-circle-gradiant {
    background: radial-gradient(circle at 50% 50%, rgba(47, 85, 212, 0.1) 0%, rgba(47, 85, 212, 0.1) 33.333%, rgba(47, 85, 212, 0.3) 33.333%, rgba(47, 85, 212, 0.3) 66.666%, rgba(47, 85, 212, 0.5) 66.666%, rgba(47, 85, 212, 0.5) 99.999%);
  }
  
  .it-home {
    -webkit-animation: animate-left-to-right 60s linear infinite;
            animation: animate-left-to-right 60s linear infinite;
  }
  
  @-webkit-keyframes animate-left-to-right {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 0;
    }
  }
  
  @keyframes animate-left-to-right {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 0;
    }
  }
  
  .home-dashboard img {
    position: relative;
    top: 60px;
    z-index: 1;
  }
  
  @media (min-width: 768px) {
    .home-dashboard.onepage-hero {
      overflow: hidden;
    }
  }
  
  .classic-saas-image .bg-saas-shape:after {
    bottom: 3rem;
    left: 0;
    width: 75rem;
    height: 55rem;
    border-radius: 20rem;
  }
  
  @media (max-width: 767px) {
    .classic-saas-image .bg-saas-shape:after {
      left: 10rem;
      width: 30rem;
      height: 30rem;
      border-radius: 10rem;
    }
  }
  
  @media (max-width: 768px) {
    .classic-saas-image .bg-saas-shape img,
    .freelance-hero .bg-shape img {
      max-width: 550px;
    }
  }
  
  @media (max-width: 767px) {
    .classic-saas-image .bg-saas-shape img,
    .freelance-hero .bg-shape img {
      max-width: 100%;
      height: auto;
    }
  }
  
  



  .bg-primary,
.btn-primary,
.btn-soft-primary:hover, 
.btn-soft-primary:focus, 
.btn-soft-primary:active, 
.btn-soft-primary.active, 
.btn-soft-primary.focus,
.btn-outline-primary:hover, 
.btn-outline-primary:focus, 
.btn-outline-primary:active, 
.btn-outline-primary.active, 
.btn-outline-primary.focus, 
.btn-outline-primary:not(:disabled):not(.disabled):active,
.pagination .page-item.active .page-link,
.nav-pills .nav-link.active,
#preloader #status .spinner .double-bounce1, 
#preloader #status .spinner .double-bounce2,
.social-icon li a:hover,
#topnav .navbar-toggle.open span:hover,
.bg-animation-left.crypto-home:after,
.bg-animation-left.task-management-home:after,
.classic-app-image .bg-app-shape:after,
.classic-saas-image .bg-saas-shape:after,
.swiper-slider-hero .swiper-container .swiper-button-prev:hover,
.features.fea-primary:hover,
.tns-nav button.tns-nav-active,
.ribbon.ribbon-primary span,
.sidebar .widget .tagcloud > a:hover,
.work-container.work-modern .icons .work-icon:hover,
.timeline-page .timeline-item .date-label-left:after, 
.timeline-page .timeline-item .duration-right:after,
#navmenu-nav li.account-menu.active .navbar-link,
#navmenu-nav li.account-menu:hover .navbar-link,
.form-check-input.form-check-input:checked,
.qs-datepicker-container .qs-squares .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover, 
.qs-datepicker-container .qs-squares .qs-square.qs-current {
    background-color: #2f55d4 !important;
}

.text-primary,
.bg-soft-primary,
.component-wrapper.sticky-bar li a:hover, 
.component-wrapper.sticky-bar li a:focus,
.btn-soft-primary,
.btn-outline-primary,
.dropdown-primary .dropdown-menu .dropdown-item:hover, 
.dropdown-primary .dropdown-menu .dropdown-item.active, 
.dropdown-primary .dropdown-menu .dropdown-item:active, 
.dropdown-primary .dropdown-menu .dropdown-item.focus, 
.dropdown-primary .dropdown-menu .dropdown-item:focus,
.alert-outline-primary,
.breadcrumb .breadcrumb-item a:hover,
.breadcrumb .breadcrumb-item.active,
#topnav .has-submenu.active .submenu li.active > a,
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a,
#topnav .navigation-menu > li > a:hover, 
#topnav .navigation-menu > li > a:active,
#topnav.scroll .navigation-menu > li:hover > a, 
#topnav.scroll .navigation-menu > li.active > a,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a,
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, 
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a,
#topnav .navigation-menu > li .submenu li a:hover,
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a,
#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li .submenu li a:hover,
#topnav .navigation-menu > li.has-submenu.open > a,
#topnav .has-submenu.active a,
.features.feature-clean .title:hover,
.features.fea-primary .content .title:hover,
.categories a:hover,
.explore-feature:hover .icon,
.explore-feature:hover .title,
.pricing-rates.business-rate:hover .title,
.blog .content .title:hover,
.blog .content .post-meta .readmore:hover,
.sidebar .widget .blog-categories li a:hover,
.sidebar .widget .post-recent .post-recent-content a:hover,
.media-list .media .media-heading:hover,
.job-box .position a:hover,
.job-box .company-name:hover,
.candidate-list .name:hover,
.company-list .name:hover,
.work-container.work-classic .content .title:hover, 
.work-container.work-grid .content .title:hover, 
.work-container.work-modern .content .title:hover,
.event-schedule .content .title:hover,
.courses-desc .content .title:hover,
.container-filter li.active, .container-filter li:hover,
.shop-list .content .product-name:hover,
.team .name:hover,
.accordion .accordion-button:not(.collapsed),
.accordion .accordion-item .accordion-button:before,
#navmenu-nav li.active a {
    color: #2f55d4 !important;
}

.btn-primary,
.btn-soft-primary:hover, 
.btn-soft-primary:focus, 
.btn-soft-primary:active, 
.btn-soft-primary.active, 
.btn-soft-primary.focus,
.btn-outline-primary,
.btn-outline-primary:hover, 
.btn-outline-primary:focus, 
.btn-outline-primary:active, 
.btn-outline-primary.active, 
.btn-outline-primary.focus, 
.btn-outline-primary:not(:disabled):not(.disabled):active,
.alert-primary,
.alert-outline-primary,
.pagination .page-item.active .page-link,
.form-check-input.form-check-input:checked,
.form-control:focus,
.form-check-input:focus,
.social-icon li a:hover,
#topnav .has-submenu.active.active .menu-arrow,
#topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow,
#topnav.scroll .navigation-menu > li:hover > .menu-arrow, 
#topnav.scroll .navigation-menu > li.active > .menu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, 
#topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow,
#topnav .navigation-menu > li:hover > .menu-arrow,
.swiper-slider-hero .swiper-container .swiper-button-next:hover,
.pricing-rates.starter-plan,
.event-schedule:hover,
.container-filter li.active, .container-filter li:hover{
    border-color: #2f55d4 !important;
}

.bg-soft-primary,
.btn-soft-primary,
.features .icon,
.features.feature-clean .icons i,
.features:hover .image:before,
.app-feature-shape-right:after,
.app-feature-shape-left:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after,
.social-feature-left:after,
.social-feature-right:after,
.event-schedule .date .day {
    background-color: rgba(47, 85, 212, 0.1) !important;
}

.bg-soft-primary,
.btn-soft-primary {
    border: 1px solid rgba(47, 85, 212, 0.1) !important;
}

.app-feature-shape-right:after,
.app-feature-shape-left:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after,
.social-feature-left:after,
.social-feature-right:after {
    box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
}

.event-schedule .date .day {
    box-shadow: 0px 0px 2px 0.25px rgba(47, 85, 212, 0.5);
}

.btn-primary,
.btn-soft-primary,
.btn-outline-primary:hover, 
.btn-outline-primary:focus, 
.btn-outline-primary:active, 
.btn-outline-primary.active, 
.btn-outline-primary.focus, 
.btn-outline-primary:not(:disabled):not(.disabled):active {
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.alert-primary,
.pagination .page-item .page-link:hover,
::selection {
    background: rgba(47, 85, 212, 0.9);
    border-color: rgba(47, 85, 212, 0.9);
}

.social-icon li a:hover .fea-social {
    fill: #2f55d4;
}

.bg-circle-gradiant {
    background: radial-gradient(circle at 50% 50%, rgba(47, 85, 212, 0.1) 0%, rgba(47, 85, 212, 0.1) 33.333%, rgba(47, 85, 212, 0.3) 33.333%, rgba(47, 85, 212, 0.3) 66.666%, rgba(47, 85, 212, 0.5) 66.666%, rgba(47, 85, 212, 0.5) 99.999%);
}

.tns-nav button {
    background: rgba(47, 85, 212, 0.5) !important;
}

.classic-app-image .bg-app-shape:after,
.classic-saas-image .bg-saas-shape:after,
.freelance-hero .bg-shape:after {
    box-shadow: 0 0 40px rgba(47, 85, 212, 0.5);
}

.freelance-hero .bg-shape:after {
    background-color: #99abea;
}

.key-feature .icon {
    background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.15));
}

.features .image:before {
    background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.1));
}

.home-wrapper:before {
    background: -webkit-gradient(linear, left top, right top, from(rgba(47, 85, 212, 0.3)), to(transparent));
    background: linear-gradient(90deg, rgba(47, 85, 212, 0.3), transparent);
}

a.text-primary:hover, 
a.text-primary:focus {
    color: #284bc1 !important;
}

.btn-primary:hover, 
.btn-primary:focus, 
.btn-primary:active, 
.btn-primary.active, 
.btn-primary.focus {
    background-color: #2443ac !important;
    border-color: #2443ac !important;
}

.ribbon.ribbon-primary span:before {
    border-left-color: #2443ac;
    border-top-color: #2443ac;
}

.ribbon.ribbon-primary span:after {
    border-right-color: #2443ac;
    border-top-color: #2443ac;
}

.navbarshadow {
  -webkit-box-shadow: 0px 14px 12px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 14px 12px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 14px 12px 0px rgba(0,0,0,0.75);
}